import { VChip } from 'vuetify/lib/components/VChip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mouse-cursor", attrs: { id: _vm.email } }, [
    _c(
      "div",
      { staticClass: "mouse-cursor-name" },
      [
        _c(
          VChip,
          {
            attrs: {
              small: "",
              color: _vm.mouseEvent.color,
              "text-color": "white",
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.mouseEvent.name) + "\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }