var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.images.length, function (index) {
      return _c("image-element", {
        key: index,
        attrs: {
          image: _vm.images[index - 1].picture,
          "sub-width": "24px",
          "sub-height": "24px",
          "sub-right": 10 * (index - 1) + "px",
          "sub-bottom": _vm.elementHeight,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }