import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      staticStyle: { "overflow-y": "hidden" },
      attrs: { "max-width": "600" },
      on: {
        "click:outside": function ($event) {
          return _vm.participantClose()
        },
      },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        VCard,
        [
          _c(
            VTabs,
            {
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            _vm._l(_vm.items, function (item) {
              return _c(VTab, { key: item.tab }, [
                _vm._v(
                  "\n                " + _vm._s(item.tab) + "\n            "
                ),
              ])
            }),
            1
          ),
          _c(
            VTabsItems,
            {
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c(
                VTabItem,
                [
                  _c(
                    VCard,
                    { attrs: { flat: "" } },
                    [
                      _c("ModelCanvasParticipants", {
                        key: _vm.refreshKey,
                        attrs: { checkPublic: _vm.checkPublic },
                        on: {
                          all: _vm.participantAll,
                          apply: _vm.participantApply,
                          close: _vm.participantClose,
                          add: _vm.participantAdd,
                          remove: _vm.participantRemove,
                        },
                        model: {
                          value: _vm.value,
                          callback: function ($$v) {
                            _vm.value = $$v
                          },
                          expression: "value",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VTabItem,
                [
                  _c(
                    VCard,
                    { attrs: { flat: "" } },
                    [
                      _c("ModelCanvasShare", {
                        key: _vm.refreshKey,
                        attrs: { canvasComponentName: _vm.canvasComponentName },
                        model: {
                          value: _vm.canvas,
                          callback: function ($$v) {
                            _vm.canvas = $$v
                          },
                          expression: "canvas",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }