import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    [
      _c(
        VCardText,
        [
          _c(
            VCard,
            [
              _c(
                VCardSubtitle,
                {
                  staticStyle: {
                    "font-size": "large",
                    color: "cornflowerblue",
                  },
                },
                [
                  _vm._v("\n                URL\n                "),
                  _c(
                    VBtn,
                    {
                      staticStyle: { "align-self": "start" },
                      attrs: { icon: "", small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.copyUrl()
                        },
                      },
                    },
                    [
                      _c(VIcon, { attrs: { small: "" } }, [
                        _vm._v(" mdi-content-copy"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VCardText,
                {
                  on: {
                    click: function ($event) {
                      return _vm.copyUrl()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.modelUrl))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VCardText,
        [
          _c(
            VCard,
            [
              _c(
                VCardSubtitle,
                {
                  staticStyle: {
                    "font-size": "large",
                    color: "cornflowerblue",
                  },
                },
                [
                  _vm._v("\n                IMAGE\n                "),
                  _c(
                    VBtn,
                    {
                      staticStyle: { "align-self": "start" },
                      attrs: { icon: "", small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.copyImage()
                        },
                      },
                    },
                    [
                      _c(VIcon, { attrs: { small: "" } }, [
                        _vm._v(" mdi-content-copy"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VCardText,
                [
                  _c(VImg, {
                    staticStyle: { cursor: "pointer" },
                    attrs: {
                      src: _vm.screenshot,
                      "max-width": "800",
                      "max-height": "300",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.copyImage()
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }